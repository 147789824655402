<template>
	<v-card class="mx-auto">
		<v-row justify="center">
			<v-col cols="auto">
				<v-avatar class="profile ma-5" size="200">
					<v-img v-if="create" :src="imageSrc(null, true)"></v-img>
					<v-img v-else :src="imageSrc(account.avatar, true)"></v-img>
				</v-avatar>
			</v-col>
		</v-row>

		<v-list-item color="rgba(0, 0, 0, .4)" dark>
			<v-list-item-content>
				<v-list-item-title class="text-h6 text-center">
					{{ account?.name }} &nbsp;
				</v-list-item-title>
				<v-list-item-subtitle class="text-center">{{ account?.email }} &nbsp;</v-list-item-subtitle>
			</v-list-item-content>
		</v-list-item>
		<v-card-actions>
			<v-btn v-if="create" block large rounded color="accent" :to="{name: 'NewLinkedAccount'}">
				<v-icon>mdi-plus</v-icon>
				add account
			</v-btn>
			<v-btn v-else block large rounded color="primary" @click="switchAccount(account)">
				switch to
			</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
import {toast} from "@/utils/helpers";

export default {
	name: "RLinkedAccountTile",
	props: {
		account: {
			type: Object,
		},
		create: Boolean,
	},
	computed: {
		shareCount() {
			let w = this.shares > 1 ? 'memories' : 'memory'
			return `${this.shares} ${w} shared`
		}
	},
	methods:{
		switchAccount(account) {
			this.$api.linked_account.switch(account.id)
				.then(response => {
					this.$store.set('user/user', response.data.user)
					this.$store.set('user/token', response.data.token)
					if(this.$route.name !== 'Account'){
						this.$router.push({name: 'Account'})
					}
					console.log('1111');
					toast.add(`switched account to ${response.data.user.name}`, 'success')
				})
				.catch(error => console.log(error))
				.finally(() => this.dialog = false)
		},
	}
}
</script>

<style scoped>

</style>