<template>
	<v-card class="pa-5 mt-5 text-center" color="secondary" v-if="user.public_memories_count > 0">
		<v-card-title class="mb-5 d-block text-h4">Public memories</v-card-title>
		<v-card-subtitle class="text-center">you currently have {{ user.public_memories_count }} <span v-if="user.public_memories_count > 1">memories</span><span v-else>memory</span> with public links</v-card-subtitle>
		<v-card-actions>
			<v-spacer></v-spacer>
			<v-btn color="primary" @click="revokeAll">Revoke all</v-btn>
			<v-spacer></v-spacer>
		</v-card-actions>
		<v-card-text>
			revoke all public links to your memories
		</v-card-text>
	</v-card>
</template>

<script>
import {sync} from "vuex-pathify";

export default {
	name: "RPublicMemories",
	computed: {
		user: sync('user/user')
	},
	methods: {
		revokeAll() {
			this.$api.memories.delete_all_public_links().then(() => {
				this.user.public_memories_count = 0;
				this.$toast.add('All public links revoked', 'success');
			});
		}
	}
}
</script>
