<template>
	<v-card class="pa-5 mt-5 text-center" color="secondary">
		<v-card-title class="mb-5 d-block text-h4">storage</v-card-title>
		<v-card-subtitle class="text-center">your account has currently used: <br> {{ user.storage_used }}</v-card-subtitle>
	</v-card>
</template>

<script>
import {sync} from "vuex-pathify";

export default {
	name: "RStorage",
	computed: {
		user: sync('user/user')
	}
}
</script>
