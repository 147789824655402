<template>
	<v-card class="pa-5 mt-5 text-center" color="secondary">
		<v-card-title class="mb-5 d-block text-h4">linked accounts</v-card-title>
		<v-row justify="center">
			<v-col cols="12" sm="6" lg="6">
				<v-card-text>create an additional rewind timeline for your child or an older relative.</v-card-text>
				<v-card-text>accounts need their own email address, so that profiles can be separated in the future.</v-card-text>
				<v-card-text>until then, add their memories and moments and create a private record of your child’s early years, or a life story of someone close to you. easily switch between your own profile and your linked accounts on the user menu.</v-card-text>
			</v-col>
		</v-row>
		<v-card-text>
			<v-row justify="center">
				<v-col cols="12" sm="6" lg="4" xl="3" v-for="account in user.linked_accounts" v-if="account.id !== user.id" class="mb-5">
					<r-linked-account-tile :account="account"></r-linked-account-tile>
				</v-col>
				<v-col cols="12" sm="6" lg="4" xl="3" class="">
					<r-linked-account-tile create></r-linked-account-tile>
				</v-col>
			</v-row>
		</v-card-text>
	</v-card>
</template>

<script>
import {sync} from "vuex-pathify";
import RLinkedAccountTile from "@/views/account/components/RLinkedAccountTile";

export default {
	name: "RLinkedAccounts",
	components: {RLinkedAccountTile},
	computed: {
		user: sync('user/user')
	}
}
</script>