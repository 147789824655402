<template>
	<v-container fluid>
		<v-app-bar fixed color="background" elevation="0" class="ios-bar py-lg-2 pr-lg-3">
			<v-btn icon rounded exact :to="{name: 'Home'}">
				<v-icon>mdi-arrow-left</v-icon>
			</v-btn>
			<v-spacer></v-spacer>
		</v-app-bar>
		<v-container>
			<r-account-deletion-warning v-if="user.subscription_status === 'Lapsed'" @subscribe="goToComponent('prices')"></r-account-deletion-warning>
			<r-free-trial-warning v-if="user.subscription_status === 'Trial'" @subscribe="goToComponent('prices')"></r-free-trial-warning>
			<r-profile></r-profile>
			<r-linked-accounts></r-linked-accounts>
			<r-curated></r-curated>
			<r-grid></r-grid>
			<r-storage></r-storage>
			<r-public-memories></r-public-memories>
			<r-platform-links class="mt-10"></r-platform-links>
			<r-account-footer></r-account-footer>
		</v-container>
	</v-container>
</template>

<script>
import {sync} from "vuex-pathify";
import RFreeTrialWarning from './components/RFreeTrialWarning'
import RLinkedAccounts from './components/RLinkedAccounts'
import RAccountFooter from './components/RFooter'
import RGrid from './components/RGrid'
import RStorage from './components/RStorage'
import RAccountDeletionWarning from './components/RAccountDeletionWarning'
import RPublicMemories from "@/views/account/components/RPublicMemories.vue";

export default {
	name: "Show",
	metaInfo: {
		title: 'Account',
	},
	components: {RPublicMemories, RFreeTrialWarning, RAccountFooter, RLinkedAccounts, RGrid, RAccountDeletionWarning, RStorage},
	computed: {
		user: sync('user/user'),
	},
	mounted() {
		this.getUser()
	},
	methods: {
		save() {
			console.log(12);
			this.$refs.profile.save()
		},
		getUser() {
			this.$api.users.show()
				.then(response => this.user = response.data)
				.catch(error => console.log(error))
		},
	}
}
</script>