<template>

	<v-card class="pa-5 mt-5 text-center" color="accent">
		<v-row justify="center">
			<v-col cols="12" md="8" lg="6">
				<v-card-title class="text-h4 d-block">IMPORTANT</v-card-title>
				<v-card-text>to continue using rewind to privately record your life story, please subscribe</v-card-text>
				<v-card-text>if you are still unsure, we have given you another 60 days to decide. however, during this time you can only view memories and will not be able to edit, share or upload new ones</v-card-text>
				<v-card-title class="text-h5 d-block">{{ user.days_until_deletion }} days remaining</v-card-title>
				<v-card-subtitle>after which your account and memories will be <br><span class="text-uppercase">DELETED FOREVER.</span></v-card-subtitle>
				<v-card-actions class="justify-center">
					<v-btn :to="{name: 'Store'}" color="secondary" large rounded>subscribe now</v-btn>
				</v-card-actions>
			</v-col>
		</v-row>
	</v-card>
</template>

<script>
import {sync} from "vuex-pathify";

export default {
	name: "RFreeTrialWarning",
	computed: {
		user: sync('user/user')
	}
}
</script>