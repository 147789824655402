<template>
	<v-card class="pa-5 mt-5 text-center" color="secondary">
		<v-card-title class="mb-5 d-block text-h4">timeline view</v-card-title>
		<v-card-subtitle class="text-center">switch between single view or grid view on your phone, to see more of your memories on screen.</v-card-subtitle>
		<v-card-text>
			<v-row justify="center">
				<v-col cols="auto">
					<img src="../../../assets/grid_large.jpg" alt="" width="100" class="grid_option" :class="{'grid_option--selected': !grid_view}" @click="grid_view = false">
				</v-col>
				<v-col cols="auto">
					<img src="../../../assets/grid_small.jpg" alt="" width="100" class="grid_option" :class="{'grid_option--selected': grid_view}" @click="grid_view = true">
				</v-col>
			</v-row>
		</v-card-text>
	</v-card>
</template>

<script>
import {sync} from "vuex-pathify";

export default {
	name: "RGrid",
	computed: {
		grid_view: sync('app/grid_view'),
	},
}
</script>

<style lang="scss" scoped>
.grid_option {
	border: 5px solid transparent;
	border-radius: 5px;
	padding: 10px;

	&.grid_option--selected {
		border: 5px solid #fff;
	}
}

</style>