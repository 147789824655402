<template>
	<v-card class="pa-5 mt-5 mb-5 text-center" color="accent">
		<v-card-title class="text-h4 d-block">free trial</v-card-title>
		<v-card-text>You are currently on a free trial</v-card-text>
		<v-card-title class="text-h5 d-block">{{ user.trial_days_remaining }} days remaining</v-card-title>
		<v-card-actions class="justify-center">
			<v-btn :to="{name: 'Store'}" color="secondary" large rounded>subscribe now</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
import {sync} from "vuex-pathify";

export default {
	name: "RFreeTrialWarning",
	computed: {
		user: sync('user/user')
	},
}
</script>