<template>
	<div>
		<v-card class="px-5 py-1 mt-5" color="secondary">
			<v-card-text>
				<v-row justify="center">
					<v-col cols="auto">
						<router-link :to="{name: 'ManageSubscription'}">manage subscription</router-link>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>
		<v-card class="px-5 py-1 mt-5" color="secondary">
			<v-card-text>
				<v-row justify="center">
					<v-col cols="auto"><a href="https://rewind-remind.me/wp-content/uploads/2021/06/Rewind-Life-Ltd_Terms-and-Conditions.DOCX-0001.pdf" target="_blank" referrerpolicy="no-referrer">terms & conditions</a></v-col>
				</v-row>
			</v-card-text>
		</v-card>
		<v-card class="px-5 py-1 mt-5" color="secondary">
			<v-card-text>
				<v-row justify="center">
					<v-col cols="auto"><a href="https://rewind-remind.me/wp-content/uploads/2022/11/Rewind-Life-Ltd_Privacy-Notice.DOCX.pdf" target="_blank" referrerpolicy="no-referrer">privacy policy</a></v-col>
				</v-row>
			</v-card-text>
		</v-card>
		<v-card class="px-5 py-1 mt-5" color="secondary">
			<v-card-text>
				<v-row justify="center">
					<v-col cols="auto">
					<router-link :to="{name: 'CloseAccount'}">close my account</router-link>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>
	</div>

</template>

<script>
export default {
	name: "RFooter"
}
</script>